const Buffer = require('buffer').Buffer;

type UseLocalStorageProps = {
  setToLocalStorage: (key: string, value: any) => void;
  getFromLocalStorage: (key: string) => string;
};

const useLocalStorage = (): UseLocalStorageProps => {

  const setToLocalStorage = (key: string, value: any): void => {
    const toBase64 = Buffer.from(value).toString('base64');
    localStorage.setItem(key, toBase64);
  };

  const getFromLocalStorage = (key: string): string => {
    const fromBase64 = localStorage.getItem(key) || '';
    return Buffer.from(fromBase64, 'base64').toString();
  };

  return {
    setToLocalStorage,
    getFromLocalStorage
  };

};

export default useLocalStorage;
