import React, { FC, useContext } from 'react'
import Modal from '../modal'
import { AppStateContext } from '@components/layouts/DynamicLayout'
import { makeClasses } from '@utils/styles'
import { Colors } from '@utils/css-variables'
import Breakpoints from '@utils/breakpoints'
import MapForm from '../map/MapForm'
import Notification from '../notification'
import { MapTNProps } from '@components/requestForms/__forms.utils'

interface propsTypes extends MapTNProps {
	isModalOpen: boolean
	isPrimaryBtnLoading?: boolean
	onPrimaryButton: () => void
	onCloseModal?: () => void
	onCancelBtn: () => void
	currentAddress?: AddressInfo
	proposedAddress?: any
	shouldMapDisplay?: boolean
	onPrimaryBtnMap?: () => void
	onCancelBtnMap?: () => void
}

type AddressInfo = {
	address?: string
	city?: string
	postalCode?: string
}

type ClassType = {
	heading: string
	contentRoot: string
	contentText: string
	modalContent: string
	textStyle: string
	modifyButton: string
	description: string
}

const classes: ClassType = makeClasses({
	heading: {
		fontSize: '22px',
		lineHeight: '26px',
		margin: '15px 0 30px 0',
		color: Colors.primary,
		[Breakpoints.maxWidth('sm')]: {
			fontSize: '20px'
		}
	},
	contentRoot: {
		display: 'flex'
	},
	contentText: {
		flex: 1
	},
	modalContent: {
		color: Colors.black2,
		fontSize: '16px',
		lineHeight: '26px',
		textAlign: 'start',
		'& #viewDiv': {
			height: '490px'
		}
	},
	textStyle: {
		textAlign: 'start'
	},
	modifyButton: {
		'& button': {
			[Breakpoints.maxWidth('sm')]: {
				height: '3.5rem !important',
				borderRadius: '3rem !important'
			}
		}
	},
	description: {
		marginBottom: '30px'
	}
})

const AddressValidationModal: FC<propsTypes> = ({
	isModalOpen,
	onCloseModal,
	onPrimaryButton,
	onCancelBtn,
	currentAddress,
	proposedAddress,
	isPrimaryBtnLoading,
	shouldMapDisplay,
	onPrimaryBtnMap,
	onCancelBtnMap,
	onSetPostalCode,
	onSetCity,
	onAddressInputChange,
	onRetreiveCoordinates
}) => {
	const { pageData } = useContext(AppStateContext)

	const handlePrimaryBtn = () => {
		if (shouldMapDisplay && onPrimaryBtnMap) {
			return onPrimaryBtnMap()
		}
		return onPrimaryButton()
	}

	const handleCloseModal = () => {
		if (shouldMapDisplay && onCancelBtnMap) {
			return onCancelBtnMap()
		} else if (onCloseModal) {
			return onCloseModal()
		}
	}

	return (
		<div className={classes.modifyButton}>
			<Modal
				isOpen={isModalOpen}
				cancelButtonText={
					shouldMapDisplay
						? pageData.assets?.button_cancel
						: pageData.assets?.button_ignore
				}
				primaryButtonText={
					shouldMapDisplay
						? pageData.assets?.label_confirm
						: pageData.assets?.button_use_the_proposed_address
				}
				onCancelBtn={() => onCancelBtn()}
				onPrimaryButton={() => handlePrimaryBtn()}
				onSetIsOpen={() => {}}
				onCloseModal={handleCloseModal}
				isPrimaryBtnLoading={isPrimaryBtnLoading}
				showExitBtn={shouldMapDisplay}
			>
				<>
					{!shouldMapDisplay ? (
						<div>
							<h4 className={classes.heading}>
								{pageData.assets?.label_address_validation}
							</h4>

							<p className={classes.description}>
								{pageData.assets?.modal_the_entered_address_is_not_recognized}
							</p>

							<div className={classes.contentRoot}>
								<div className={classes.contentText}>
									<strong>{pageData.assets?.label_address_entered}</strong>
									<div>
										{currentAddress?.address}
										<br />
										{currentAddress?.city} {currentAddress?.postalCode}
									</div>
								</div>
								<div className={classes.contentText}>
									<strong>{pageData.assets?.label_propesed_address}</strong>
									<div>
										{proposedAddress?.place_name}
										<br />
										{currentAddress?.city}{' '}
										{proposedAddress?.properties.postal_code}
									</div>
								</div>
							</div>
						</div>
					) : (
						<div className={classes.modalContent}>
							<Notification
								type="info"
								text={
									pageData.assets
										?.modal_infor_address_entered_must_be_recognized
								}
								textStyle={classes.textStyle}
							/>
							<MapForm
								addressPlaceholder={
									pageData.assets?.page_requestInfo_inputAddressPlaceholder
								}
								hasError={false}
								addressRequired={false}
								displayMap={true}
								displayAddressInput={true}
								onRetreiveCoordinates={onRetreiveCoordinates}
								onAddressInputChange={(input) => {
									onAddressInputChange && onAddressInputChange(input)
								}}
								onSetPostalCode={(value) => {
									onSetPostalCode && onSetPostalCode(value)
								}}
								onSetCity={onSetCity}
							/>
						</div>
					)}
				</>
			</Modal>
		</div>
	)
}

export default AddressValidationModal
